// Bootstrap files
import ReactDOM from 'react-dom/client'
import '@popperjs/core/dist/umd/popper.min'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'

// Global styling
import './src/assets/styles/global.scss'

export const shouldUpdateScroll = (args) => {
  return false
}

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
