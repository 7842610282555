exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-explore-network-js": () => import("./../../../src/pages/explore-network.js" /* webpackChunkName: "component---src-pages-explore-network-js" */),
  "component---src-pages-for-buyers-js": () => import("./../../../src/pages/for-buyers.js" /* webpackChunkName: "component---src-pages-for-buyers-js" */),
  "component---src-pages-for-suppliers-js": () => import("./../../../src/pages/for-suppliers.js" /* webpackChunkName: "component---src-pages-for-suppliers-js" */),
  "component---src-pages-general-page-js": () => import("./../../../src/pages/general-page.js" /* webpackChunkName: "component---src-pages-general-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ispo-textrends-award-apparel-edition-js": () => import("./../../../src/pages/ispo-textrends-award/apparel-edition.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-apparel-edition-js" */),
  "component---src-pages-ispo-textrends-award-js": () => import("./../../../src/pages/ispo-textrends-award.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-js" */),
  "component---src-pages-ispo-textrends-award-textile-edition-js": () => import("./../../../src/pages/ispo-textrends-award/textile-edition.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-textile-edition-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-pakistan-hub-js": () => import("./../../../src/pages/pakistan-hub.js" /* webpackChunkName: "component---src-pages-pakistan-hub-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pricing-south-asia-js": () => import("./../../../src/pages/pricing-south-asia.js" /* webpackChunkName: "component---src-pages-pricing-south-asia-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-terms-conditions-commission-js": () => import("./../../../src/pages/terms-conditions/commission.js" /* webpackChunkName: "component---src-pages-terms-conditions-commission-js" */),
  "component---src-pages-turkey-js": () => import("./../../../src/pages/turkey.js" /* webpackChunkName: "component---src-pages-turkey-js" */),
  "component---src-templates-branches-js": () => import("./../../../src/templates/branches.js" /* webpackChunkName: "component---src-templates-branches-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

